<template>
	<div class="data-list list-tab-show-page">
		<template v-if="soft_organigram.is_active">
			<template v-if="loaded">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-name">
							<div class="text">{{ $t("general.message") }}</div>
						</div>
						<div v-if="$resize && ($mq.above(1360) || $mq.between([880, 1024]))" class="column-filter column-improve">
							<div class="text">{{ $t("assessments.what_to_change") }}</div>
						</div>
						<div v-if="$resize && ($mq.above(1090) || $mq.between([420, 1024]))" class="column-filter column-type">
							<div class="text">{{ $t("assessments.stars") }}</div>
						</div>
						<div v-if="$resize && ($mq.above(1300) || $mq.between([570, 1024]))" class="column-filter column-last-update">
							<div class="text">{{ $t("general.created_at") }}</div>
						</div>
						<div class="column-filter column-user" v-if="$resize && ($mq.above(1130) || $mq.between([500, 1024]))"></div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-if="entries.length">
					<div class="row-space-tbf row-list-item" :key="'procedura' + entry.id" v-for="entry in entries">
						<div class="space-left"><icon-congratulations v-if="$resize && $mq.above(781)" /></div>
						<div class="content">
							<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
							<div class="column-name-title column-name" @click="showModal('show_evaluation', entry)">
								<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover long" :delay="{ show: 0, hide: 0 }">
									<div class="name link">{{ entry.good_things }}</div>

									<template slot="popover">
										<div class="simple-text">{{ entry.good_things }}</div>
									</template>
								</v-popover>
								<div class="icon" v-if="entry.files.length">
									<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover long" :delay="{ show: 0, hide: 0 }">
										<icon-file />

										<template slot="popover">
											<div class="simple-text">{{ entry.files.map((el) => el.filename).join(", ") }}</div>
										</template>
									</v-popover>
								</div>
							</div>
							<div class="column-name-title column-improve" v-if="$resize && ($mq.above(1360) || $mq.between([880, 1024]))">
								<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover long" :delay="{ show: 0, hide: 0 }">
									<div class="name">{{ entry.what_to_change ? entry.what_to_change : "-" }}</div>

									<template slot="popover">
										<div class="simple-text">{{ entry.what_to_change ? entry.what_to_change : "-" }}</div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && ($mq.above(1090) || $mq.between([420, 1024]))" class="column-simple-text column-type">
								<div class="text">
									<star-rating v-model="entry.stars" :show-rating="false" :star-size="15" :increment="0.5" :read-only="true"> </star-rating>
								</div>
							</div>
							<div v-if="$resize && ($mq.above(1300) || $mq.between([570, 1024]))" class="column-simple-text column-last-update">
								<div class="text">{{ entry.created_at | moment("DD MMM YYYY") }}</div>
							</div>
							<div v-if="$resize && ($mq.above(1130) || $mq.between([500, 1024]))" class="column-avatar column-user table">
								<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{ show: 0, hide: 0 }">
									<img :src="entry.evaluator.avatar" v-if="entry.evaluator.avatar" />
									<div class="user-circle" v-else>
										<icon-user />
									</div>

									<template slot="popover">
										<div class="simple-text">{{ entry.evaluator.first_name }} {{ entry.evaluator.last_name }}</div>
									</template>
								</v-popover>
							</div>

							<div class="column-edit-mobile" v-if="$resize && $mq.below(500) && userRight">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef' + entry.id" :id="'dropdownEdit' + entry.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu' + entry.id" :aria-labelledby="'dropdownEdit' + entry.id">
										<div class="dropdown-item" @click="showModal('delete', { type: 'entry_evaluation', from: 'index_evaluations', model: entry })">
											<div class="simple-text">{{ $t("general.delete") }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501) && userRight" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef' + entry.id" :id="'dropdownEdit' + entry.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu' + entry.id" :aria-labelledby="'dropdownEdit' + entry.id">
									<div class="dropdown-item" @click="showModal('delete', { type: 'entry_evaluation', from: 'index_evaluations', model: entry })">
										<div class="simple-text">{{ $t("general.delete") }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="row-space-tbf row-list-item">
						<div class="space-left"></div>
						<div class="content">
							<div class="column-empty-list">
								<div class="name"><img src="/build/images/no-results-found-inline.svg" />{{ $t("congratulations.empty-inline", { name: userName }) }}</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</template>
			<loader-items-list-2 v-else />
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
import NotModule from "@/components/General/NotModule";
import IconUser from "../../Icons/User";
import IconEdit from "../../Icons/EditDots";
import IconCongratulations from "../../Icons/Congratulations";
import IconEditMobile from "../../Icons/Edit";
import IconFile from "../../Icons/File";
import LoaderItemsList2 from "../../PagesLoaders/ItemsList2";
import StarRating from "vue-star-rating";

export default {
	components: {
		NotModule,
		IconUser,
		IconEdit,
		IconEditMobile,
		IconCongratulations,
		LoaderItemsList2,
		StarRating,
		IconFile,
	},
	props: {
		userName: String,
		userSlug: String,
		dateRange: Object,
		userRight: Boolean,
	},
	computed: {
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
	watch: {
		$route(to, from) {
			if (this.soft_organigram.is_active) {
				this.getEvaluationsEntries();
			}
		},
	},
	data() {
		return {
			loaded: false,
			entries: [],
		};
	},
	async mounted() {
		if (this.soft_organigram.is_active) {
			await this.getEvaluationsEntries();
		}

		this.$root.$on("refreshEvaluationsIndex", () => {
			if (this.soft_organigram.is_active) {
				this.getEvaluationsEntries();
			}
		});
	},
	methods: {
		async getEvaluationsEntries() {
			let paramsRequest = {
				start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
				end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
			};

			await axios
				.get(`/${this.$route.params.slug}/user-evaluations`, { params: paramsRequest })
				.then(({ data }) => {
					this.entries = data.data;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 404) {
							this.$router.push({ name: "not-found" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true;
					}, 0);
				});
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
	},
};
</script>
