<template>
	<div class="page-show-item change_responsive show-user" v-if="loaded">
		<div class="main-content">
			<div class="header-with-tabs row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<template v-if="user.rights.show">
						<div v-if="$resize && $mq.above(1025) && !$mq.between([1025, 1890])" class="tabs">
							<div class="title-tab" :class="{ active: ['calendar_activities', 'list_tasks'].includes(activeTab) }" @click="changeTab('calendar_activities')">
								{{ $t("show_user.work_calendar") }}
							</div>
							<div class="title-tab" :class="{ active: activeTab == 'congratulations' }" @click="changeTab('congratulations')">{{ $t("show_user.tab_congratulations") }}</div>
							<div class="title-tab" :class="{ active: activeTab == 'warnings' }" @click="changeTab('warnings')">{{ $t("show_user.tab_warnings") }}</div>
							<div class="title-tab" :class="{ active: activeTab == 'assessments' }" @click="changeTab('assessments')">{{ $t("show_user.tab_assessments") }}</div>
							<div class="title-tab" :class="{ active: activeTab == 'objectives' }" @click="changeTab('objectives')">{{ $t("show_user.tab_objectives") }}</div>
							<div class="title-tab" :class="{ active: activeTab == 'procedures' }" @click="changeTab('procedures')">{{ $t("show_user.tab_procedures") }}</div>
						</div>
						<div v-if="$resize && ($mq.below(1024) || $mq.between([1025, 1890]))" class="actions-dropdown">
							<div class="filter-dropdown dropdown first">
								<button class="btn-tbf white drop" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`show_user.tab_${activeTab}`) }}</span>
									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item" :class="{ active: activeTab == 'calendar_activities' }" @click="changeTab('calendar_activities')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_calendar_activities") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'list_tasks' }" @click="changeTab('list_tasks')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_list_tasks") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'congratulations' }" @click="changeTab('congratulations')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_congratulations") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'warnings' }" @click="changeTab('warnings')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_warnings") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'assessments' }" @click="changeTab('assessments')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_assessments") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'objectives' }" @click="changeTab('objectives')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_objectives") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'key_results' }" @click="changeTab('key_results')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_key_results") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'promises' }" @click="changeTab('promises')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_promises") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'procedures' }" @click="changeTab('procedures')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_procedures") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'processes' }" @click="changeTab('processes')">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_processes") }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
					<div class="actions">
						<div v-if="$resize && $mq.above(500)" class="actions-dropdown">
							<div v-if="['objectives', 'key_results', 'promises'].includes(activeTab) && $resize && $mq.above(1025) && !$mq.between([1025, 1890])" class="filter-dropdown dropdown">
								<button class="btn-tbf white drop" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`show_user.tab_${activeTab}`) }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item" :class="{ active: activeTab == 'objectives' }" @click="(activeTab = 'objectives'), changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_objectives") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'key_results' }" @click="(activeTab = 'key_results'), changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_key_results") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'promises' }" @click="(activeTab = 'promises'), changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_promises") }}</span>
										</div>
									</div>
								</div>
							</div>

							<!-- STATUS OBJECTIVES -->
							<div v-if="['objectives', 'key_results'].includes(activeTab) && soft_objectives.is_active" class="filter-dropdown dropdown">
								<button class="btn-tbf white drop-roles" id="dropdownFilterStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="!selectedStatus">{{ $t("filters.by_status") }}</span>
									<span class="text" v-else>{{ $t(`show_user.filter_status_obj_kr.${selectedStatus}`) }}</span>

									<div class="icon-filter" v-if="!selectedStatus"><icon-arrow /></div>
									<div class="icon-clear" @click.stop="clearFilter('selectedStatus')" v-else><icon-close /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilterStatus">
									<template v-for="statusItem in statusObjKr">
										<div class="dropdown-item" @click="selectOptionFilter('selectedStatus', statusItem)" v-bind:class="{ active: selectedStatus.includes(statusItem) }">
											<div class="checkbox">
												<div class="checkmark"></div>
												<span class="text">{{ $t(`show_user.filter_status_obj_kr.${statusItem}`) }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>

							<div v-if="['processes', 'procedures'].includes(activeTab) && $resize && $mq.above(1025) && !$mq.between([1025, 1890])" class="filter-dropdown dropdown">
								<button class="btn-tbf white drop" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`show_user.tab_${activeTab}`) }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item" :class="{ active: activeTab == 'processes' }" @click="(activeTab = 'processes'), changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_processes") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'procedures' }" @click="(activeTab = 'procedures'), changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.tab_procedures") }}</span>
										</div>
									</div>
								</div>
							</div>

							<div v-if="['calendar_activities', 'list_tasks'].includes(activeTab)" class="filter-dropdown dropdown">
								<button class="btn-tbf white drop" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`show_user.custom_type_responsibilities.${activeTab}`) }}</span>
									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item" :class="{ active: activeTab == 'calendar_activities' }" @click="(activeTab = 'calendar_activities'), changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.custom_type_responsibilities.calendar_activities") }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{ active: activeTab == 'list_tasks' }" @click="(activeTab = 'list_tasks'), changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t("show_user.custom_type_responsibilities.list_tasks") }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div
							class="filter-btns"
							:class="{ 'mr-0': !user.rights.evaluation_review_crud || !['congratulations', 'warnings', 'assessments'].includes(activeTab) }"
							v-if="
								(['objectives', 'key_results', 'promises'].includes(activeTab) && soft_objectives.is_active) ||
									(['warnings', 'assessments', 'congratulations', 'list_tasks'].includes(activeTab) && soft_organigram.is_active)
							"
						>
							<date-range-picker
								ref="picker"
								class="calendar-filter mobile"
								:opens="$resize && $mq.between([1025, 1342]) ? 'center' : 'left'"
								:locale-data="calendarFilterSettings"
								:singleDatePicker="$mq.below(650) ? 'range' : false"
								:showWeekNumbers="false"
								:showDropdowns="false"
								:ranges="false"
								:autoApply="true"
								:linkedCalendars="true"
								v-model="dateRange"
							>
								<template v-slot:input="picker">
									<div class="input-picker">
										<div class="icon"><icon-date /></div>
										<div class="date" v-if="picker.startDate == picker.endDate">{{ picker.startDate | moment("DD MMM YY") }}</div>
										<div class="date" v-else>{{ picker.startDate | moment("DD MMM YY") }} - {{ picker.endDate | moment("DD MMM YY") }}</div>
										<icon-arrow class="icon-arrow right" />
									</div>
								</template>
							</date-range-picker>
						</div>

						<button
							class="btn-tbf blue show mg-left"
							:class="{ mobile: $resize && $mq.below(1254) }"
							@click="
								['congratulations', 'warnings'].includes(activeTab)
									? showModal('congratulation_and_warning', { user: user, activeForm: activeTab })
									: showModal('evaluation', { user: user, activeForm: activeTab })
							"
							v-if="
								user.rights.evaluation_review_crud &&
									['congratulations', 'warnings', 'assessments'].includes(activeTab) &&
									(user.id != $auth.user().id || $auth.user().is_entrepreneur) &&
									soft_organigram.is_active
							"
						>
							<div class="icon">
								<icon-plus />
							</div>
							<span v-if="$resize && $mq.above(1255)" class="text">{{ $t("general.add") }}</span>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="tab-content">
				<div class="row-space-tbf" v-if="activeTab == 'calendar_activities'">
					<div class="space-left"></div>
					<div class="content">
						<calendar
							class="calendar"
							ref="calendarComponent"
							:data="{
								slug: user.slug,
								id: user.id,
								right: user.rights.evaluation_review_crud,
								from: 'user',
								viewToolbar: true,
								filtersData: {
									user: { slug: user.slug, id: user.id, right: user.right },
									sources: 'personal',
									types: ['responsibilities', 'tasks'],
									dateRange: dateRange,
									loadedKey: loadedQueryFiltersKey,
									filters_dashboard: { objectives: [], key_results: [], projects: [], tags: [], roles: [], general: [] },
								},
								first_name: user.first_name,
								last_name: user.last_name,
							}"
							:key="uniqueKeyClendar"
						/>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf" v-if="activeTab == 'list_tasks'">
					<div class="space-left"></div>
					<div class="content">
						<tab-list-activities
							class="from-user-page"
							ref="listActivities"
							:filterJson="
								JSON.stringify({
									user: { slug: user.slug, id: user.id, right: user.rights.evaluation_review_crud },
									sources: 'personal',
									types: ['responsibilities', 'tasks'],
									dateRange: dateRange,
									loadedKey: loadedQueryFiltersKey,
									filters_dashboard: { objectives: [], key_results: [], projects: [], tags: [], roles: [], general: [] },
								})
							"
						/>
					</div>
					<div class="space-right"></div>
				</div>

				<tab-congratulations
					v-if="activeTab == 'congratulations'"
					:dateRange="dateRange"
					:userSlug="user.slug"
					:userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`"
					:userRight="user.rights.evaluation_review_crud"
				/>
				<tab-warnings
					v-if="activeTab == 'warnings'"
					:dateRange="dateRange"
					:userSlug="user.slug"
					:userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`"
					:userRight="user.rights.evaluation_review_crud"
				/>
				<tab-assessments
					v-if="activeTab == 'assessments'"
					:dateRange="dateRange"
					:userSlug="user.slug"
					:userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`"
					:userRight="user.rights.evaluation_review_crud"
				/>
				<tab-promises v-if="activeTab == 'promises'" :dateRange="dateRange" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" />
				<tab-objectives
					v-if="activeTab == 'objectives'"
					:dateRange="dateRange"
					:status="selectedStatus"
					:userSlug="user.slug"
					:userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`"
				/>
				<tab-key-results
					v-if="activeTab == 'key_results'"
					:dateRange="dateRange"
					:status="selectedStatus"
					:userSlug="user.slug"
					:userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`"
				/>
				<tab-procedures v-if="activeTab == 'procedures'" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" />
				<tab-processes v-if="activeTab == 'processes'" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" />
			</div>
		</div>
		<div class="sidebar-user">
			<div class="space-left-custom"></div>
			<div class="content">
				<div class="actions-header" v-if="$auth.user().rights.admins_crud || user.rights.delete">
					<button @click="$router.push({ name: 'user-edit', params: { slug: user.slug } })" v-if="$auth.user().rights.admins_crud">
						<div class="text ">{{ $t("general.edit") }}</div>
					</button>

					<button class="delete-btn" v-if="user.rights.delete" @click="showModal('delete', { type: 'user', from: 'show_user', model: user })">
						<div class="text ">{{ $t("general.delete") }}</div>
					</button>
				</div>

				<div class="header-user">
					<div class="data">
						<div class="name">{{ `${user.first_name} ${user.last_name ? user.last_name : ""}` }}</div>
						<div class="tag" v-if="!user.dummy_account">{{ user.email }}</div>
					</div>

					<div class="image">
						<img :src="user.avatar" v-if="user.avatar" />
						<div class="user-circle" v-else>
							<icon-user />
						</div>
					</div>
					<div v-if="user.date_of_birth && moment(user.date_of_birth).format('DD-MM') == moment().format('DD-MM')" class="birthdayIcons">
						<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="label" :delay="{ show: 100, hide: 0 }">
							<img src="/build/images/cake.png" class="icon" />
							<div slot="popover">
								<div class="simple-text" v-if="$auth.user().id == user.id">{{ $t("show_user.birthday.toYou") }}</div>
								<div class="simple-text" v-else>{{ $t("show_user.birthday.toHim") }}</div>
							</div>
						</v-popover>
					</div>
				</div>

				<div class="box-label-text" v-if="user.status == 'archived' && user.archived_reason">
					<div class="label">{{ $t("user_archivate.reason") }}</div>
					<div class="text">{{ user.archived_reason }}</div>
				</div>

				<div class="box-label-text" v-if="user.roles.length">
					<div class="label">{{ $t("general.roles") }}</div>
					<div class="text">{{ user.roles.map((a) => a.name).join(", ") }}</div>
					<div class="link" @click="showModal('responsibilities_unique', { userSlug: user.slug, userName: `${user.first_name} ${user.last_name}` })" v-if="user.rights.show">
						{{ $t("user-details.view_responsibilities") }}
					</div>
				</div>

				<div class="box-label-text" v-if="user.job_function && soft_organigram.is_active">
					<div class="label">{{ $t("create-user.job-function") }}</div>
					<div class="text">{{ user.job_function }}</div>
				</div>

				<div class="box-label-text" v-if="user.departments.length">
					<div class="label">{{ $t("profile.departments") }}</div>
					<div class="text">{{ user.departments.join(", ") }}</div>
				</div>

				<div class="work-today" @click="showModal('user_free_days', { user_id: user.id, user_name: `${user.first_name} ${user.last_name ? user.last_name : ''}` })">
					<div class="circle-status" :class="[user.free_day ? 'red' : 'green']"></div>
					<div class="text">{{ user.free_day ? $t("days_off.user_free_day") : $t("days_off.user_work_day") }}</div>
				</div>

				<div class="work-today" @click="showModal('procedure_check', { userSlug: user.slug })" v-if="user.rights.show">
					<icon-learn class="icon play-icon" />
					<div class="text">{{ $t("show_user.procedure-tracking", { n: procedureData.procedure_checks }) }}</div>
				</div>

				<div class="accordion-sidebar" v-if="user.rights.show">
					<div class="header-accordion" data-toggle="collapse" data-target="#collapsePromiseObjective">
						<div class="icon">
							<div
								class="circle-status"
								:class="{
									red: ['not_completed', 'not_set'].includes(promiseObjective.status),
									yellow: ['pending'].includes(promiseObjective.status),
									green: ['completed'].includes(promiseObjective.status),
									grey: ['no_key_result', 'not_working'].includes(promiseObjective.status) || !promiseObjective.ready,
								}"
							></div>
						</div>

						<div class="title">{{ $t("promise_objectives.title") }}</div>
						<div class="actions">
							<button class="arrow-collapse show" type="button" data-toggle="collapse" data-target="#collapsePromiseObjective" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse show content-accordion" id="collapsePromiseObjective">
						<div class="box-radius">
							<div class="data">
								<div class="value" v-if="promiseObjective.name">{{ promiseObjective.name }}</div>
								<div class="value" v-else-if="promiseObjective.status == 'not_set'">{{ $t("promise_objectives.status.not_set") }}</div>
								<div class="value" v-else-if="promiseObjective.status == 'not_working'">{{ $t("promise_objectives.status.not_working") }}</div>
								<div class="value" v-else-if="promiseObjective.status == 'no_key_result'">{{ $t("promise_objectives.status.no_key_result") }}</div>
							</div>

							<div class="data" v-if="promiseObjective.why_not">
								<div class="label">{{ $t("promise_objectives.why_not") }}</div>
								<div class="value">{{ promiseObjective.why_not }}</div>
							</div>

							<div class="data" v-if="promiseObjective.key_results_names && promiseObjective.key_results_names.length">
								<div class="label">{{ $t("promise_objectives.key_results") }}</div>
								<div class="value">
									<div class="list-item" v-for="kr in promiseObjective.key_results_names">{{ kr }}</div>
								</div>
							</div>

							<timeline ref="timeline" :data="{ user: user, view: 'show_user' }" />
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="soft_procedures.is_active && procedureData && procedureDataLoaded && user.rights.show">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseProceduresStats">
						<div class="icon"><icon-chart /></div>

						<div class="title">{{ $t("show_user.statistics") }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseProceduresStats" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseProceduresStats">
						<div class="box-radius">
							<div class="inline-data">
								<div class="label">{{ $t("show_user.total_time_used") }}</div>
								<div class="value">{{ procedureData.analytics.total_time ? procedureData.analytics.total_time : "00:00:00" }}</div>
							</div>
							<div class="inline-data">
								<div class="label">{{ $t("show_user.number_of_visits") }}</div>
								<div class="value">{{ procedureData.analytics.entries ? procedureData.analytics.entries : "0" }}</div>
							</div>
							<div class="inline-data">
								<div class="label">{{ $t("show_user.average_time") }}</div>
								<div class="value">{{ procedureData.analytics.average_time ? procedureData.analytics.average_time : "00:00:00" }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="soft_organigram.is_active && user.work_schedule && user.rights.show">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseWorkSchedules">
						<div class="icon"><icon-work-schedule /></div>

						<div class="title">{{ $t("show_user.work-schedule") }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseWorkSchedules" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseWorkSchedules">
						<div class="box-radius">
							<div class="inline-data" v-for="schedule in user.work_schedule.schedules">
								<div class="label">{{ $t(`general.${schedule.day}`) }}</div>
								<div class="value">{{ `${schedule.start_time} - ${schedule.end_time}` }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="soft_organigram.is_active && user.skills.length && user.rights.show">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseSkills">
						<div class="icon"><icon-skill /></div>

						<div class="title">{{ $t("show_user.skills") }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseSkills" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseSkills">
						<div class="box-radius">
							<div class="inline-data" v-for="skill in user.skills">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="label" :delay="{ show: 100, hide: 0 }">
									<div class="label">{{ skill.name }}</div>
									<template slot="popover">
										<div class="simple-text">{{ skill.description }}</div>
									</template>
								</v-popover>

								<div class="value">{{ $t(`skill.type.${skill.level}`) }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="soft_organigram.is_active && user.foreign_languages.length && user.rights.show">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseForeignLanguages">
						<div class="icon"><icon-globe /></div>

						<div class="title">{{ $t("show_user.foreign_languages") }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseForeignLanguages" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseForeignLanguages">
						<div class="box-radius">
							<div class="data" v-for="foreign_language in user.foreign_languages">
								<div class="label">{{ foreign_language.name }}</div>
								<div class="value">
									{{
										foreign_language.levels
											.map((el) => {
												return `${$t("foreign_languages." + el["type"])}: ${el["level"]}`;
											})
											.join(", ")
									}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="soft_organigram.is_active">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapsePersonalData">
						<div class="icon"><icon-user /></div>

						<div class="title">{{ $t("show_user.user-details") }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapsePersonalData" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapsePersonalData">
						<div class="box-radius">
							<div class="inline-data" v-if="user.phone">
								<div class="label">{{ $t("profile.phone") }}</div>
								<div class="value">{{ user.phone }}</div>
							</div>
							<div class="inline-data" v-if="user.workstation">
								<div class="label">{{ $t("create-user.workstation") }}</div>
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="value" :delay="{ show: 100, hide: 0 }">
									<div class="value">{{ user.workstation.name }}</div>
									<template slot="popover">
										<div class="simple-text">{{ user.workstation.description }}</div>
									</template>
								</v-popover>
							</div>
							<div class="inline-data" v-if="user.date_of_employment">
								<div class="label">{{ $t("show_user.date-of-employment") }}</div>
								<div class="value">{{ user.date_of_employment | moment("DD MMM YYYY") }}</div>
							</div>
							<div class="inline-data" v-if="user.vacation_days">
								<div class="label">{{ $t("show_user.vacations-days") }}</div>
								<div class="value">{{ user.vacation_days }}</div>
							</div>

							<div class="subtitle-accordion">{{ $t("show_user.personal-data") }}</div>

							<div class="inline-data" v-if="user.business_email">
								<div class="label">{{ $t("show_user.email") }}</div>
								<div class="value">{{ user.business_email }}</div>
							</div>
							<div class="inline-data" v-if="user.business_phone">
								<div class="label">{{ $t("show_user.business_phone") }}</div>
								<div class="value">{{ user.business_phone }}</div>
							</div>
							<div class="inline-data" v-if="user.emergency_phone">
								<div class="label">{{ $t("show_user.emergency_phone") }}</div>
								<div class="value">{{ user.emergency_phone }}</div>
							</div>
							<div class="inline-data" v-if="user.address">
								<div class="label">{{ $t("show_user.address") }}</div>
								<div class="value">{{ user.address }}</div>
							</div>
							<div class="inline-data" v-if="user.date_of_birth">
								<div class="label">{{ $t("show_user.data-of-birth") }}</div>
								<div class="value">{{ user.date_of_birth | moment("DD MMM YYYY") }}</div>
							</div>
							<div class="inline-data" v-if="user.timezone">
								<div class="label">{{ $t("show_user.timezone") }}</div>
								<div class="value">{{ user.timezone }}</div>
							</div>
							<div class="inline-data">
								<div class="label">{{ $t("show_user.married") }}</div>
								<div class="value">{{ user.married ? $t("general.yes") : $t("general.no") }}</div>
							</div>
							<div class="inline-data">
								<div class="label">{{ $t("show_user.childrens") }}</div>
								<div class="value">{{ user.has_children ? $t("general.yes") : $t("general.no") }}</div>
							</div>
							<div class="inline-data" v-if="user.blood_type">
								<div class="label">{{ $t("create-user.blood-type") }}</div>
								<div class="value">{{ user.blood_type }}</div>
							</div>

							<div class="subtitle-accordion" v-if="user.facebook_link || user.linkedin_link || user.instagram_link || user.twitter_link || user.tiktok_link || user.youtube_link">
								{{ $t("show_user.social-data") }}
							</div>
							<div class="inline-data" v-if="user.facebook_link">
								<div class="label">{{ $t("show_user.facebook") }}</div>
								<div class="value">
									<a target="_blank" :href="user.facebook_link">{{ user.facebook_link }}</a>
								</div>
							</div>
							<div class="inline-data" v-if="user.linkedin_link">
								<div class="label">{{ $t("show_user.linkedin") }}</div>
								<div class="value">
									<a target="_blank" :href="user.linkedin_link">{{ user.linkedin_link }}</a>
								</div>
							</div>
							<div class="inline-data" v-if="user.instagram_link">
								<div class="label">{{ $t("show_user.instagram") }}</div>
								<div class="value">
									<a target="_blank" :href="user.instagram_link">{{ user.instagram_link }}</a>
								</div>
							</div>
							<div class="inline-data" v-if="user.twitter_link">
								<div class="label">{{ $t("show_user.twitter") }}</div>
								<div class="value">
									<a target="_blank" :href="user.twitter_link">{{ user.twitter_link }}</a>
								</div>
							</div>
							<div class="inline-data" v-if="user.tiktok_link">
								<div class="label">{{ $t("show_user.tiktok") }}</div>
								<div class="value">
									<a target="_blank" :href="user.tiktok_link">{{ user.tiktok_link }}</a>
								</div>
							</div>
							<div class="inline-data" v-if="user.youtube_link">
								<div class="label">{{ $t("show_user.youtube") }}</div>
								<div class="value">
									<a target="_blank" :href="user.youtube_link">{{ user.youtube_link }}</a>
								</div>
							</div>

							<div class="subtitle-accordion" v-if="user.hobbies && user.hobbies.length">{{ $t("show_user.hobbies") }}</div>
							<div class="data hobbies-data" v-if="user.hobbies && user.hobbies.length">
								<div class="value">
									{{
										user.hobbies
											.map((el) => {
												return el;
											})
											.join(", ")
									}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="user.rights.show">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseRightsData">
						<div class="icon"><icon-file-right /></div>

						<div class="title">{{ $t("profile.rights") }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseRightsData" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseRightsData">
						<div class="box-radius">
							<template v-if="soft_organigram.is_active">
								<div class="subtitle-accordion">{{ $t("profile.module_organigram") }}</div>
								<div class="inline-data">
									<div class="label">{{ $t("profile.general_right") }}</div>
									<div class="value">{{ $t(`profile.rights_name.${rights.organigram.general}`) }}</div>
								</div>
								<div class="inline-data">
									<div class="label">{{ $t("profile.project_manager_right") }}</div>
									<div class="value">{{ rights.organigram.project_manager === 1 ? $t("general.yes") : $t("general.no") }}</div>
								</div>
								<div class="inline-data">
									<div class="label">{{ $t("profile.evaluate_past_responsibility_right") }}</div>
									<div class="value">{{ rights.organigram.evaluate_past_responsibility === 1 ? $t("general.yes") : $t("general.no") }}</div>
								</div>
							</template>

							<template v-if="soft_objectives.is_active">
								<div class="subtitle-accordion">{{ $t("profile.module_objectives") }}</div>
								<div class="inline-data">
									<div class="label">{{ $t("profile.general_right") }}</div>
									<div class="value">{{ $t(`profile.rights_name.${rights.objectives.general}`) }}</div>
								</div>
							</template>

							<template v-if="soft_procedures.is_active">
								<div class="subtitle-accordion">{{ $t("profile.module_procedures") }}</div>
								<div class="inline-data">
									<div class="label">{{ $t("profile.general_right") }}</div>
									<div class="value">{{ $t(`profile.rights_name.${rights.procedures.general}`) }}</div>
								</div>
								<div class="inline-data" v-if="rights.procedures.procedure_role_admin">
									<div class="label">{{ $t("profile.rights_name.procedure_admin") }}</div>
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 100, hide: 0 }">
										<div class="value">
											{{ rights.procedures.procedure_role_admin.slice(0, 2).join(", ") }}
											<span v-if="rights.procedures.procedure_role_admin.length > 2">+ {{ rights.procedures.procedure_role_admin.length - 2 }}</span>
										</div>

										<div slot="popover">
											<div class="simple-text">{{ rights.procedures.procedure_role_admin.join(", ") }}</div>
										</div>
									</v-popover>
								</div>

								<div class="inline-data" v-if="rights.procedures.process_admin">
									<div class="label">{{ $t("profile.rights_name.processes_admin") }}</div>
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 100, hide: 0 }">
										<div class="value">
											{{ rights.procedures.process_admin.slice(0, 1).join(", ") }}
											<span v-if="rights.procedures.process_admin.length > 1">+ {{ rights.procedures.process_admin.length - 1 }}</span>
										</div>

										<div slot="popover">
											<div class="simple-text">{{ rights.procedures.process_admin.join(", ") }}</div>
										</div>
									</v-popover>
								</div>
							</template>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="soft_organigram.is_active && user.files.length && user.rights.show">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseFiles">
						<div class="icon"><icon-file /></div>

						<div class="title">{{ $t("show_user.files") }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseFiles" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseFiles">
						<div class="box-radius with-files">
							<div class="files">
								<div class="file" v-for="file in user.files">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="label" :delay="{ show: 100, hide: 0 }" :disabled="!file.expire_date">
										<a class="file-box" :href="file.file_path" target="_blank">
											<div class="title">
												<div class="icon"><icon-file /></div>
												<div class="name">{{ file.filename }}</div>
											</div>
											<div class="expire-date" v-if="file.expire_date">
												<span>{{ $t("create-user.date-file-expire") }} {{ file.expire_date | moment("DD MMM YYYY") }}</span>
											</div>
											<div class="expire-date" v-else>
												<span>{{ $t("create-user.not-file-expire") }}</span>
											</div>
										</a>
										<template slot="popover">
											<div class="simple-text">{{ file.emails ? file.emails.join(", ") : "" }}</div>
										</template>
									</v-popover>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="user.id == $auth.user().id && $auth.user().email == 'alex@tbf.ro'">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseCalendarSync">
						<div class="icon"><icon-date /></div>

						<div class="title">{{ $t("show_user.calendar_sync") }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseCalendarSync" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseCalendarSync">
						<div class="box-radius">
							<div class="data">
								<div class="value">{{ $auth.user().google_calendar ? $t("show_user.calendar_sync_ready") : $t("show_user.calendar_sync_desc") }}</div>
							</div>

							<div class="inline-data center">
								<div class="value">
									<button
										class="btn-tbf google-button"
										:class="[$auth.user().google_calendar ? 'white-red' : 'white-blue']"
										@click="$auth.user().google_calendar ? $root.$emit('google_sign_out') : $root.$emit('google_sign_in')"
									>
										<div class="icon">
											<img src="/build/images/google-calendar.svg" />
										</div>
										<div class="text">{{ $auth.user().google_calendar ? $t("sync_calendar.unsync") : $t("sync_calendar.sync") }}</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="user.rights.show">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseExport">
						<div class="icon"><icon-download /></div>
						<div class="title">{{ $t("general.export") }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseExport" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseExport">
						<div class="box-radius border-0">
							<div class="description">{{ $t("show_user.export_type_description") }}</div>
							<div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t("show_user.export_type_evaluations") }}</span>
									<input type="checkbox" value="evaluations" v-model="filtersPdf" />
									<span class="checkmark"></span>
								</label>
							</div>
							<div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t("show_user.export_type_congratulations") }}</span>
									<input type="checkbox" value="congratulations" v-model="filtersPdf" />
									<span class="checkmark"></span>
								</label>
							</div>
							<div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t("show_user.export_type_warnings") }}</span>
									<input type="checkbox" value="warnings" v-model="filtersPdf" />
									<span class="checkmark"></span>
								</label>
							</div>
							<div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t("show_user.export_type_objectives") }}</span>
									<input type="checkbox" value="objectives" v-model="filtersPdf" />
									<span class="checkmark"></span>
								</label>
							</div>
							<div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t("show_user.export_type_key_results") }}</span>
									<input type="checkbox" value="key_results" v-model="filtersPdf" />
									<span class="checkmark"></span>
								</label>
							</div>
							<div class="inline-data">
								<div class="label">{{ $t("general.pdf") }}</div>
								<div class="value link" @click="downloadPdf">
									<div class="loader-spin" v-if="loadingExportPdf"></div>
									{{ $t("general.download") }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="space-right-custom"></div>
		</div>
	</div>
</template>

<script>
import IconSkill from "../../Icons/Skill";
import IconWorkSchedule from "../../Icons/WorkSchedule";
import IconGlobe from "../../Icons/Globe";
import IconFile from "../../Icons/FilePdf";
import IconSearch from "../../Icons/Search";
import IconArrow from "../../Icons/Arrow";
import IconUser from "../../Icons/User";
import IconTrash from "../../Icons/Trash";
import IconClose from "../../Icons/Close";
import IconDownload from "../../Icons/Download";
import IconEdit from "../../Icons/EditDots";
import IconSettings from "../../Icons/Settings";
import IconDate from "../../Icons/Date";
import IconEditMobile from "../../Icons/Edit";
import IconPlus from "../../Icons/Plus";
import IconRole from "../../Icons/Role";
import IconLearn from "../../Icons/LearnProcedures";
import IconChart from "../../Icons/ChartColumn";
import IconFileRight from "../../Icons/FileRigtht";

import TabCongratulations from "./Congratulations";
import TabWarnings from "./Warnings";
import TabAssessments from "./Assessments";
import TabPromises from "./Promises";
import TabObjectives from "./Objectives";
import TabKeyResults from "./KeyResults";
import TabProcedures from "./Procedures";
import TabProcesses from "./Processes";
import TabListActivities from "../Dashboard/ListActivities";

import Timeline from "../Dashboard/Timeline";
import Calendar from "../../Pages/Calendar";

export default {
	components: {
		IconSkill,
		IconWorkSchedule,
		IconGlobe,
		IconFile,
		IconSearch,
		IconArrow,
		IconUser,
		IconTrash,
		IconClose,
		IconEdit,
		IconSettings,
		IconDownload,
		IconEditMobile,
		IconDate,
		IconPlus,
		IconRole,
		IconLearn,
		IconChart,
		TabCongratulations,
		TabWarnings,
		TabAssessments,
		TabPromises,
		TabObjectives,
		TabKeyResults,
		TabProcedures,
		TabProcesses,
		TabListActivities,
		Timeline,
		IconFileRight,
		Calendar,
	},
	data() {
		return {
			loaded: false,
			loadedList: false,
			loadedQueryFiltersKey: 0,
			uniqueKeyClendar: 1,
			search_filter: "",
			queryFiltersCount: 0,
			extendFilters: false,
			filterRoles: [],
			selectedRoles: [],
			searchRoles: "",
			filterCategories: [],
			selectedCategories: [],
			searchCategories: "",
			procedures: [],
			sortBy: "",
			sortDirection: "asc",
			user: {},
			activeTab: this.$route.query.tab ? this.$route.query.tab : "calendar_activities",
			dateRange: {
				startDate: moment().add(-30, "days"),
				endDate: moment(),
			},
			calendarFilterSettings: {
				firstDay: 1,
				format: "DD-MM-YYYY",
				daysOfWeek: moment.weekdaysMin(),
				monthNames: moment.monthsShort(),
			},
			activeProceduri: false,
			activeObiective: false,
			loadingExportPdf: false,
			filtersPdf: [],
			promiseObjective: { ready: false },
			procedureData: {},
			procedureDataLoaded: false,
			statusObjKr: ["active", "overdue", "upcoming", "finished"],
			selectedStatus: "",
			rights: {
				organigram: {},
				objectives: {},
				procedures: {},
			},
		};
	},
	computed: {
		sortedProcedures: function() {
			return this.procedures.sort((p1, p2) => {
				let modifier = 1;
				if (this.sortDirection === "desc") modifier = -1;
				if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
				if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
				return 0;
			});
		},
		filteredProcedures() {
			return getByKeyword(this.sortedProcedures, this.search_filter);
		},
		filteredRoles() {
			return getByKeywordFilter(this.filterRoles, this.searchRoles);
		},
		soft_procedures() {
			return this.$store.getters["applications/getApplication"]("Proceduri");
		},
		soft_objectives() {
			return this.$store.getters["applications/getApplication"]("Obiective");
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
	watch: {
		search_filter: function(val) {
			var data = Object.assign({}, this.$route.query);
			data["search"] = this.search_filter;

			if (this.search_filter == "") {
				delete data["search"];
			}

			this.$router.push({ query: data });
		},
		dateRange: function(val) {
			this.addFilterToRouteQuery("date");
		},
		soft_procedures(newValue) {
			if (newValue.is_active) {
				this.getProcedureData();
			}
		},
		soft_objectives(newValue) {
			if (newValue.is_active) {
				this.getObjectivePromise();
			}
		},
	},
	async mounted() {
		this.getRights();

		await this.getUserData();
		await this.checkQueryFilters();
		await this.getFilters();

		setTimeout(() => {
			if (this.$route.query.tab) {
				this.activeTab = this.$route.query.tab;
			}
		}, 0);

		if (this.soft_procedures && this.soft_procedures.is_active) {
			this.getProcedureData();
		}
		if (this.soft_objectives && this.soft_objectives.is_active) {
			this.getObjectivePromise();
		}

		this.$root.$on("open_responsibilities", () => {
			this.showModal("responsibilities_unique", { userSlug: this.user.slug, userName: `${this.user.first_name} ${this.user.last_name}` });
		});
	},
	methods: {
		async getUserData() {
			await axios
				.get(`/users/${this.$route.params.slug}`)
				.then(({ data }) => {
					this.user = data.data;
					this.procedures = data.data.procedures;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 404) {
							this.$router.push({ name: "not-found" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					var title = this.user.first_name + " " + this.user.last_name;
					this.$root.$emit("navbar_title", title);
				});
		},
		async getFilters() {
			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, { params: { roles: true, categories: true } })
				.then(({ data }) => {
					this.filterRoles = data.data.roles;
					this.filterCategories = data.data.categories;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		async getRights() {
			// Get Organigrama rights
			axios.get(`/users/${this.$route.params.slug}/rights`).then(({ data }) => {
				this.rights.organigram = {
					general: data.data.right,
					project_manager: data.data.project_manager,
					evaluate_past_responsibility: data.data.evaluate_past_responsibility,
				};
			});

			axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/users/${this.$route.params.slug}/rights`).then(({ data }) => {
				this.rights.objectives = {
					general: data.data,
				};
			});

			axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/users/${this.$route.params.slug}/rights`).then(({ data }) => {
				this.rights.procedures = {
					general: data.data.right,
				};

				if (data.data.procedure_role_admin) {
					this.rights.procedures.procedure_role_admin = data.data.procedure_role_admin;
				}

				if (data.data.process_admin) {
					this.rights.procedures.process_admin = data.data.process_admin;
				}
			});
		},
		showProcedure(itemSlug) {
			this.$router.push({ name: "procedure-show", params: { slug: itemSlug } });
		},
		selectOptionFilter(filter, value) {
			if (filter == "selectedStatus") {
				this[filter] = value == this[filter] ? "" : value;
				return true;
			}

			if (typeof value == "string") {
				if (this[filter].includes(value)) {
					this[filter].splice(this[filter].indexOf(value), 1);
				} else {
					this[filter].push(value);
				}
			} else {
				if (this[filter].includes(parseInt(value))) {
					this[filter].splice(this[filter].indexOf(parseInt(value)), 1);
				} else {
					this[filter].push(parseInt(value));
				}
			}

			this.addFilterToRouteQuery(filter);
		},
		addFilterToRouteQuery(filterName) {
			var dataQuery = Object.assign({}, this.$route.query);

			if (filterName == "selectedRoles" || filterName == "all") {
				dataQuery["roles"] = this.selectedRoles.toString();
			}
			if (filterName == "selectedCategories" || filterName == "all") {
				dataQuery["categories"] = this.selectedCategories.toString();
			}

			if (filterName == "date") {
				dataQuery["start_date"] = moment(this.dateRange.startDate).format("DD-MM-YYYY");
				dataQuery["end_date"] = moment(this.dateRange.endDate).format("DD-MM-YYYY");
			}

			Object.keys(dataQuery).map(function(key, value) {
				if (dataQuery[key] == "") {
					delete dataQuery[key];
				}
			});

			this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length;

			this.$router.push({ query: dataQuery });
			this.loadedQueryFiltersKey++;
		},
		clearFilter(filter) {
			if (filter == "all") {
				this.selectedRoles = [];
				this.selectedCategories = [];
				this.selectedStatus = "";
			} else {
				this[filter] = filter == "selectedStatus" ? "" : [];
			}

			this.addFilterToRouteQuery(filter);
		},
		checkQueryFilters() {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "start_date") {
					this.dateRange.startDate = moment(dataQuery[key], "DD-MM-YYYY");
				}
				if (key == "end_date") {
					this.dateRange.endDate = moment(dataQuery[key], "DD-MM-YYYY");
				}

				if (key == "open_responsibilities") {
					this.showModal("responsibilities_unique", { userSlug: this.user.slug, userName: `${this.user.first_name} ${this.user.last_name}` });
				}
			});

			this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length;

			this.loadedQueryFiltersKey++;
		},
		sortList(column) {
			if (column === this.sortBy) {
				this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
			}
			this.sortBy = column;
		},
		editProcedure(procedureSlug) {
			this.$router.push({ name: "procedure-edit", params: { slug: procedureSlug } });
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		goCreateEntry(item) {
			this.activeForm = item;
			if (item == "congratulations" || "warnings") {
				var type = "congratulation_and_warning";
			} else if (item == "assessments") {
				var type = "evaluation";
			}
			var data = {
				user: this.user,
				activeForm: this.activeForm,
			};
			this.showModal(type, data);
		},
		changeTab(item) {
			this.activeTab = item;

			this.selectedRoles = [];
			this.$router.push({ name: "user-show", query: { tab: item } });
			this.activeForm = "";
		},
		downloadPdf() {
			this.loadingExportPdf = true;

			axios
				.get(`reports/${this.user.slug}/user-pdf`, {
					params: { filter: this.filtersPdf.join(",") },
					responseType: "blob",
				})
				.then((response) => {
					let blob = new Blob([response.data], { type: "application/octet-stream" });
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = `${this.user.first_name} ${this.user.last_name ? this.user.last_name : ""}.pdf`;

					let fileLink = document.createElement("a");
					fileLink.href = fileURL;
					fileLink.setAttribute("download", fileName);
					document.body.appendChild(fileLink);

					fileLink.click();

					setTimeout(() => {
						this.loadingExportPdf = false;
					}, 100);
				});
		},
		getObjectivePromise() {
			axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/sync-data/${this.user.slug}/check-promise`).then(({ data }) => {
				this.promiseObjective = { ...data.data, ready: true };
			});
		},
		getProcedureData() {
			axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/${this.user.slug}/custom-data`).then(({ data }) => {
				this.procedureData = data.data;
				this.procedureDataLoaded = true;
			});
		},
	},
};
function getByKeyword(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>

<style lang="scss">
@import "../../../styles/pages/user_show.scss";
.filter-btns {
	display: flex;
	align-items: center;
	&.mr-0 {
		margin-right: 0;
	}
	.btn-default-tbf {
		height: 36px;
		font-size: 12px;
		font-weight: 500;
		color: $primary;
		padding: 0 15px;
		min-width: 110px;
		text-align: left;
		display: flex;
		align-items: center;
		.icon-arrow {
			margin-left: auto;
		}
		.clearFilter {
			margin-left: auto;
			height: 12px;
			margin-right: -2px;
		}
	}
	.calendar-filter {
		margin-left: 20px;
	}
	.filter-status {
		margin-left: 20px;
	}
}
.input-picker {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	.icon {
		margin-right: 10px;
		justify-content: center;
		display: flex;
		svg {
			height: 16px;
			width: auto;
			.fill-color {
				fill: $placeholderColor;
			}
		}
	}
	.text {
		color: $placeholderColor;
	}
	.date {
		flex: 1;
		font-size: 12px;
		color: $primary;
		text-transform: capitalize;
		line-height: normal;
	}
}
.responsibility {
	margin: 0 0 10px 0;
	padding-left: 15px;
	line-height: 1.3;
	margin-top: 10px;
}
.vue-daterange-picker {
	&.mobile {
		.form-control {
			height: 36px !important;
		}
	}
}
.btn-tbf {
	&.show {
		height: 36px !important;
	}
	&.drop {
		min-width: 100px !important;
	}
	&.mg-left {
		margin-left: 20px;
	}
}
</style>
